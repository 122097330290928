<template>
  <b-card class="card-region-card">
    <h4>
      {{ kota }}
    </h4>
    <div
      v-if="layanan"
      class="layanan-list mt-1"
    >
      <b-badge
        v-for="item in layanan"
        :key="item.vertikal"
        variant="primary"
        class="layanan-badge"
      >
        {{ vertikalList(item.vertikal) }}
      </b-badge>
    </div>
    <b-img
      :src="require('@/assets/images/illustration/Pot1.svg')"
      class="region-card"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BImg, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BImg,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: ['kota', 'layanan'],
  methods: {
    vertikalList(vl) {
      const vertikalVariant = {
        vl01: 'Akupunktur',
        vl02: 'Auto',
        vl03: 'Barber',
        vl04: 'Bekam',
        vl05: 'Beauty',
        vl06: 'Cleaning',
        vl07: 'Massage',
        vl08: 'Cuci AC',
      }

      return vertikalVariant[vl]
    },
  },
}
</script>

<style>
  .layanan-badge {
    margin-right: 4px;
    margin-bottom: 4px;
  }
  .card-region-card {
    object-fit: cover;
  }
  .layanan-list {
    width: 80%;
  }
</style>
