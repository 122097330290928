<template>
  <div>
    <transition name="fade">
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <region-card
          :kota="regionData.nama_kota"
        />
        <b-card>
          <b-row
            class="align-items-start"
          >
            <b-col
              md="9"
            >
              <h4>
                Daftar Layanan
              </h4>
            </b-col>
            <b-col
              md="3"
            >
              <b-button
                class="mb-2"
                variant="primary"
                block
                @click="getNewVertikal()"
              >
                Tambah Vertikal Baru
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
            >
              <b-tabs
                pills
                nav-class="nav-pill-primary"
              >
                <b-tab
                  v-for="item in vertikal"
                  :key="item.kode_vertikal"
                  :title="item.nama_vertikal"
                  @click="getLayanan(item.kode_vertikal)"
                />
              </b-tabs>
            </b-col>
          </b-row>
          <hr>
          <b-overlay
            :show="showOverlay"
            rounded="sm"
          >
            <div v-if="available">
              <div class="d-flex flex-md-row flex-column">
                <div class="mb-25 mr-0 mb-md-0 mr-md-2">
                  <small>Kategori UMK</small>
                  <div
                    v-if="!showEditUMK"
                    class="d-flex mt-25"
                  >
                    <h5
                      class="mr-50"
                    >
                      {{ getUMK(currentVertikal.kode) }}
                    </h5>
                    <b-link
                      @click="showEditUMK = true"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="20"
                      />
                    </b-link>
                  </div>
                  <div
                    v-if="showEditUMK"
                    class="d-flex mt-25 align-items-center"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="28"
                      @click="showEditUMK = false"
                    />
                    <v-select
                      v-model="selectedUMK"
                      :options="pilihUMK"
                      placeholder="Pilih UMK"
                      :clearable="false"
                      class="ml-50 mr-50 w-100"
                    />
                    <b-button
                      variant="primary"
                      @click="editUMK"
                    >
                      <feather-icon icon="CheckIcon" />
                    </b-button>
                  </div>
                </div>

                <div class="mb-25 mr-0 mb-md-0 mr-md-2">
                  <small>Radius</small>
                  <div
                    v-if="!showEditRadius"
                    class="d-flex mt-25"
                  >
                    <h5
                      class="mr-50"
                    >
                      {{ regionData.radius }}KM
                    </h5>
                    <b-link
                      @click="showEditRadius = true"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="20"
                      />
                    </b-link>
                  </div>
                  <div
                    v-if="showEditRadius"
                    class="d-flex mt-25 align-items-center"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="28"
                      @click="showEditRadius = false"
                    />
                    <b-form-input
                      v-model="selectedRadius"
                      placeholder="Input Radius"
                      class="mr-25 ml-25"
                    />
                    <b-button
                      variant="primary"
                      @click="editRadius"
                    >
                      <feather-icon icon="CheckIcon" />
                    </b-button>
                  </div>
                </div>

                <div class="mb-25 mr-0 mb-md-0 mr-md-2">
                  <small>Status</small>
                  <div
                    v-if="!showEditStatus"
                    class="d-flex mt-25"
                  >
                    <h5
                      class="mr-50"
                    >
                      {{ currentVertikal.status == true ? 'Tersedia' : 'Tidak Tersedia' }}
                    </h5>
                    <b-link>
                      <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                      >
                        <template #button-content>
                          <feather-icon
                            icon="EditIcon"
                            size="20"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="!currentVertikal.status"
                          @click="editStatus(currentVertikal.status)"
                        >
                          <span class="align-middle ml-50">Tersedia</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-else
                          @click="editStatus(currentVertikal.status)"
                        >
                          <span class="align-middle ml-50">Tidak Tersedia</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-link>
                  </div>
                  <div
                    v-if="showEditStatus"
                    class="d-flex mt-25 align-items-center"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="28"
                      @click="showEditStatus = false"
                    />
                  </div>
                </div>
              </div>
              <b-row
                class="mt-2 mb-2"
              >
                <b-col
                  v-for="item in layanan"
                  :key="item.kode_layanan"
                  md="4"
                >
                  <b-card
                    border-variant="secondary"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h6>
                        {{ item.nama_layanan }}
                      </h6>
                      <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          @click="hapusLayanan(item)"
                        >
                          <feather-icon
                            class="text-danger"
                            icon="CopyIcon"
                          />
                          <span class="align-middle ml-50 text-danger">Hapus Layanan</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  offset-md="8"
                  md="4"
                >
                  <b-button
                    variant="outline-primary"
                    block
                    @click="tambahLayanan()"
                  >
                    Tambah Layanan
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div
              v-else
              class="d-flex flex-column"
            >
              <div class="mx-auto">
                <h5
                  class="text-muted text-center mt-2"
                >
                  Layanan belum tersedia
                </h5>
                <b-button
                  variant="outline-primary"
                  class="mt-2 mb-2 ml-1"
                  @click="tambahLayanan()"
                >
                  Tambah Layanan
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="mt-2 mb-2 ml-1"
                  @click="tambahSemuaLayanan()"
                >
                  Tambah Semua Layanan
                </b-button>
              </div>
            </div>
          </b-overlay>
        </b-card>
      </div>
    </transition>

    <vue-bottom-sheet
      ref="myBottomSheet"
      class="p-1"
      :rounded="false"
      :click-to-close="false"
    >
      <div class="bottom-sheet">
        <div class="d-flex justify-content-between mb-1 align-items-center">
          <h5>Pilih layanan yang ingin ditambahkan</h5>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="closeBottomSheet"
          />
        </div>
        <v-select
          v-model="layananUpdate"
          dir="ltr"
          label="nama_layanan"
          :options="allLayanan"
          class="Invoice-filter-select mt-2 mb-2"
          placeholder="Pilih Layanan"
          multiple
        />
        <v-select
          v-if="currentVertikal.kode === null"
          v-model="umkVertikal"
          label="label"
          :options="pilihUMK"
          class="Invoice-filter-select mt-2 mb-2"
          placeholder="Pilih UMK"
          :clearable="false"
        />
        <b-button
          variant="outline-primary"
          block
          @click="tambahVertikalLayanan()"
        >
          Tambahkan Layanan
        </b-button>
      </div>
    </vue-bottom-sheet>
    <br>
    <vue-bottom-sheet
      ref="bottomSheetAllLayanan"
      class="p-1"
      :rounded="false"
      :click-to-close="false"
    >
      <div class="bottom-sheet">
        <div class="d-flex justify-content-between mb-1 align-items-center">
          <h5>Pilih UMK yang anda inginkan</h5>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="closeBottomSheetAllLayanan"
          />
        </div>
        <v-select
          v-model="umkUpdate"
          label="label"
          :options="pilihUMK"
          class="Invoice-filter-select mt-2 mb-2"
          placeholder="Pilih UMK"
        />
        <b-button
          variant="outline-primary"
          block
          @click="submitTambahSemuaLayanan()"
        >
          Tambahkan
        </b-button>
      </div>
    </vue-bottom-sheet>
    <vue-bottom-sheet
      ref="bottomSheetNewVertikal"
      class="p-1"
      :rounded="false"
      :click-to-close="false"
    >
      <div class="bottom-sheet">
        <div class="d-flex justify-content-between mb-1 align-items-center">
          <h5>Pilih Vertikal Yang Tersedia</h5>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="closeBottomSheetVertikal"
          />
        </div>
        <v-select
          v-model="vertikalLayanan"
          label="label"
          :options="allVertikal"
          class="Invoice-filter-select mt-2 mb-2"
          placeholder="Pilih Vertikal"
          :clearable="false"
        />
        <v-select
          v-model="umkVertikal"
          label="label"
          :options="pilihUMK"
          class="Invoice-filter-select mt-2 mb-2"
          placeholder="Pilih UMK"
          :clearable="false"
        />
        <v-select
          v-model="pilihanLayanan"
          label="label"
          :options="[
            { label: 'Pilih Manual Nanti', value: 'manual', },
            { label: 'Pilih Semua Layanan Yang Tersedia', value: 'all', },
          ]"
          class="Invoice-filter-select mt-2 mb-2"
          placeholder="Pilih Layanan"
          :clearable="false"
        />
        <b-button
          variant="primary"
          block
          @click="newVertikal()"
        >
          Tambahkan
        </b-button>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
/* eslint-disable radix */
import firebase from 'firebase/app'
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BTab,
  BTabs,
  BSpinner,
  BOverlay,
  BLink,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import { db } from '@/firebase'
import RegionCard from './RegionCard.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BTab,
    BTabs,
    vSelect,
    BButton,
    BSpinner,
    RegionCard,
    BOverlay,
    BLink,
    VueBottomSheet,
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormInput,
  },
  props: ['id'],
  data() {
    return {
      loading: true,
      vertikal: [],
      layanan: [],
      allLayanan: [],
      layananSelect: '',
      regionData: {},
      showOverlay: false,
      available: false,
      layananUpdate: [],
      currentVertikal: '',
      umkUpdate: '',
      pilihUMK: [
        { value: 0, label: 'UMK 2 juta' },
        { value: 1, label: 'UMK 3 juta' },
        { value: 2, label: 'UMK 4 juta' },
      ],
      selectedUMK: '',
      selectedRadius: '',
      showEditUMK: false,
      showEditRadius: false,
      showEditStatus: false,
      allVertikal: [],
      vertikalLayanan: '',
      umkVertikal: '',
      pilihanLayanan: '',
    }
  },
  created() {
    this.getVertikal()
  },
  methods: {
    async getVertikal() {
      this.vertikal = []
      const kodeKota = this.id
      const kodeProvinsi = kodeKota.substring(0, 2)
      await db.collection('region').doc(kodeProvinsi).collection('kota').doc(kodeKota)
        .get()
        .then(snapshot => {
          this.regionData = snapshot.data()
          snapshot.data().layanan.forEach(layanan => {
            db.collection('layanan').doc(layanan.vertikal).get().then(doc => {
              this.vertikal.push(doc.data())
            })
          })
          this.loading = false
          this.getLayanan(this.regionData.layanan[0].vertikal)
        })
    },
    /* eslint-disable arrow-body-style */
    async getLayanan(kode) {
      this.showOverlay = true
      this.layanan = []
      this.allLayanan = []
      const filtered = this.regionData.layanan.filter(item => {
        return item.vertikal === kode
      })[0]
      this.currentVertikal = filtered

      await db.collection('layanan').doc(kode).collection('layanan').get()
        .then(snapshot => {
          snapshot.docs.forEach(doc => {
            this.allLayanan.push(doc.data())
          })
        })

      if (filtered.layanan_tersedia.length) {
        filtered.layanan_tersedia.forEach(layanan => {
          this.layanan.push(this.allLayanan.filter(item => {
            return item.kode_layanan === layanan
          })[0])
          this.showOverlay = false
          this.available = true
        })
      } else {
        this.showOverlay = false
        this.available = false
      }
    },
    tambahLayanan() {
      this.layananUpdate = null
      this.$refs.myBottomSheet.open()
      const filterLayananTersedia = this.allLayanan.filter(item => {
        return this.layanan.indexOf(item) < 0
      })
      this.allLayanan = [...new Set(filterLayananTersedia)]
    },
    tambahVertikalLayanan() {
      if (this.layananUpdate === null || this.umkVertikal === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Harap lengkapi data terlebih dahulu',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      } else {
        const dataOld = this.regionData.layanan.filter(item => {
          return item.vertikal === this.currentVertikal.vertikal
        })[0]

        let kodeArea = dataOld.kode

        if (this.currentVertikal.kode === null) {
          kodeArea = this.umkVertikal.value
        }

        db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
          .update({
            layanan: firebase.firestore.FieldValue.arrayRemove(dataOld),
          })
          .then(() => {
            const arr = []
            dataOld.layanan_tersedia.forEach(item => {
              arr.push(item)
            })
            this.layananUpdate.forEach(item => {
              arr.push(item.kode_layanan)
            })
            db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
              .update({
                layanan: firebase.firestore.FieldValue.arrayUnion({
                  kode: kodeArea,
                  layanan_tersedia: arr,
                  leader: dataOld.leader,
                  status: dataOld.status,
                  vertikal: dataOld.vertikal,
                }),
              })
              .then(() => {
                this.layanan = []
                this.umkVertikal = ''
                const index = this.regionData.layanan.indexOf(dataOld)
                this.regionData.layanan[index].layanan_tersedia = arr
                this.regionData.layanan[index].kode = kodeArea
                this.getLayanan(this.currentVertikal.vertikal)
                this.closeBottomSheet()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Layanan berhasil ditambahkan',
                    icon: 'CheckIcon',
                    variant: 'success',
                    position: 'bottom',
                  },
                })
              })
          })
      }
    },
    tambahSemuaLayanan() {
      this.$refs.bottomSheetAllLayanan.open()
    },
    submitTambahSemuaLayanan() {
      const dataOld = this.regionData.layanan.filter(item => {
        return item.vertikal === this.currentVertikal.vertikal
      })[0]

      db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
        .update({
          layanan: firebase.firestore.FieldValue.arrayRemove(dataOld),
        })
        .then(() => {
          const arr = []
          this.allLayanan.forEach(item => {
            arr.push(item.kode_layanan)
          })
          db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
            .update({
              layanan: firebase.firestore.FieldValue.arrayUnion({
                kode: this.umkUpdate.value,
                layanan_tersedia: arr,
                leader: dataOld.leader,
                status: dataOld.status,
                vertikal: dataOld.vertikal,
              }),
            })
            .then(() => {
              this.layanan = []
              const index = this.regionData.layanan.indexOf(dataOld)
              this.regionData.layanan[index].layanan_tersedia = arr
              this.getLayanan(this.currentVertikal.vertikal)
              this.closeBottomSheetAllLayanan()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Layanan anda berhasil ditambahkan',
                  icon: 'CheckIcon',
                  variant: 'success',
                  position: 'bottom',
                },
              })
            })
        })
    },
    closeBottomSheetAllLayanan() {
      this.$refs.bottomSheetAllLayanan.close()
    },
    closeBottomSheet() {
      this.$refs.myBottomSheet.close()
    },
    closeBottomSheetVertikal() {
      this.$refs.bottomSheetNewVertikal.close()
    },
    getUMK(kode) {
      let umk

      if (kode == null) {
        umk = 'Belum diset'
      } else {
        umk = this.pilihUMK.filter(el => {
          return el.value === kode
        })[0].label
      }

      return umk
    },
    editStatus() {
      db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
        .update({
          layanan: firebase.firestore.FieldValue.arrayRemove(this.currentVertikal),
        })
        .then(() => {
          db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
            .update({
              layanan: firebase.firestore.FieldValue.arrayUnion({
                kode: this.currentVertikal.kode,
                layanan_tersedia: this.currentVertikal.layanan_tersedia,
                leader: this.currentVertikal.leader,
                status: !this.currentVertikal.status,
                vertikal: this.currentVertikal.vertikal,
              }),
            })
            .then(() => {
              this.layanan = []
              const index = this.regionData.layanan.indexOf(this.currentVertikal)
              this.regionData.layanan[index].layanan_tersedia = this.currentVertikal.layanan_tersedia
              this.currentVertikal.status = !this.currentVertikal.status
              this.getLayanan(this.currentVertikal.vertikal)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Status berhasil diubah',
                  icon: 'CheckIcon',
                  variant: 'success',
                  position: 'bottom',
                },
              })
            })
        })
    },
    editUMK() {
      db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
        .update({
          layanan: firebase.firestore.FieldValue.arrayRemove(this.currentVertikal),
        })
        .then(() => {
          db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
            .update({
              layanan: firebase.firestore.FieldValue.arrayUnion({
                kode: this.selectedUMK.value,
                layanan_tersedia: this.currentVertikal.layanan_tersedia,
                leader: this.currentVertikal.leader,
                status: this.currentVertikal.status,
                vertikal: this.currentVertikal.vertikal,
              }),
            })
            .then(() => {
              this.layanan = []
              const index = this.regionData.layanan.indexOf(this.currentVertikal)
              this.regionData.layanan[index].layanan_tersedia = this.currentVertikal.layanan_tersedia
              this.showEditUMK = false
              this.currentVertikal.kode = this.selectedUMK.value
              this.getLayanan(this.currentVertikal.vertikal)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Kategori UMK berhasil diubah',
                  icon: 'CheckIcon',
                  variant: 'success',
                  position: 'bottom',
                },
              })
            })
        })
    },
    editRadius() {
      this.$swal({
        title: 'Yakin ingin mengubah radius?',
        text: 'Konfirmasi jika anda ingin mengubah radius region',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(response => {
        if (response.value) {
          db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
            .update({
              radius: parseInt(this.selectedRadius),
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil mengubah radius',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })

              this.regionData.radius = this.selectedRadius
              this.showEditRadius = false
              this.selectedRadius = ''
            })
        }
      })
    },
    hapusLayanan(item) {
      this.$swal({
        title: 'Hapus layanan ini ?',
        text: 'Konfirmasi jika anda ingin menghapus data layanan',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(response => {
        if (response.value) {
          const dataOld = this.regionData.layanan.filter(element => {
            return element.vertikal === this.currentVertikal.vertikal
          })[0]
          db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
            .update({
              layanan: firebase.firestore.FieldValue.arrayRemove(dataOld),
            })
            .then(() => {
              const arr = []
              this.layanan.forEach(result => {
                arr.push(result.kode_layanan)
              })
              db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
                .update({
                  layanan: firebase.firestore.FieldValue.arrayUnion({
                    kode: dataOld.kode,
                    layanan_tersedia: arr,
                    leader: dataOld.leader,
                    status: dataOld.status,
                    vertikal: dataOld.vertikal,
                  }),
                })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Berhasil menghapus data layanan',
                      icon: 'TrashIcon',
                      variant: 'success',
                    },
                  })
                })
            })
          const index = this.layanan.indexOf(item)
          this.layanan.splice(index, 1)
        }
      })
    },
    getNewVertikal() {
      const arr = []
      const old = []

      db.collection('layanan').get().then(snapshot => {
        snapshot.docs.forEach(item => {
          arr.push({
            label: item.data().nama_vertikal,
            value: item.data().kode_vertikal,
            raw: item.data(),
          })
        })

        this.regionData.layanan.forEach(el => {
          old.push(el.vertikal)
        })

        const filtered = arr.filter(item => {
          return old.indexOf(item.value) < 0
        })

        this.allVertikal = [...new Set(filtered)]
      })
      this.$refs.bottomSheetNewVertikal.open()
    },
    async newVertikal() {
      let allLayanan = []

      if (this.vertikalLayanan === '' || this.umkVertikal === '' || this.pilihanLayanan === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Harap lengkapi data terlebih dahulu',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }

      if (this.pilihanLayanan.value === 'all') {
        const arr = []
        await db.collection('layanan').doc(this.vertikalLayanan.value).collection('layanan').get()
          .then(snapshot => {
            snapshot.docs.forEach(doc => {
              arr.push(doc.data().kode_layanan)
            })
          })
        allLayanan = [...new Set(arr)]
      }

      const updateData = {
        kode: this.umkVertikal.value,
        layanan_tersedia: allLayanan,
        leader: null,
        status: true,
        vertikal: this.vertikalLayanan.value,
      }

      db.collection('region').doc(this.id.substring(0, 2)).collection('kota').doc(this.id)
        .update({
          layanan: firebase.firestore.FieldValue.arrayUnion(updateData),
        })
        .then(() => {
          this.vertikalLayanan = ''
          this.umkVertikal = ''
          this.pilihanLayanan = ''
          this.regionData.layanan.push(updateData)
          this.getVertikal()
          this.closeBottomSheetVertikal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Vertikal baru berhasil ditambahkan',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.bottom-sheet {
  padding: 16px 16px 100px 16px
}
</style>
